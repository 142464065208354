import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import routeNames from '../../constants/routeNames';
import { ContentContext } from '../../contexts/ContentContext';

export default function Footer({ className }) {
    const { content } = useContext(ContentContext);
    const _footer = content?.global_settings?.footer;

    return (
        <section className={`footer-section ${className}`}>
            <footer id="site-footer">
                {_footer?.image && (
                    <img className='footer-image' src={_footer?.image} alt='footer-image' />
                )}
                <p className="faqs-text" dangerouslySetInnerHTML={{__html : _footer?.faqs}}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: _footer?.content }}>
                </p>

                    <b className="copyright" dangerouslySetInnerHTML={{ __html: _footer?.copyright }}>
                    </b>
            </footer>
        </section>
    );
}

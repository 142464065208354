import React from 'react';
import AppStoreImage from '../../assets/images/cc/app-store.png'
import PlayStoreImage from '../../assets/images/cc/play-store.png'
import './AppLinks.css'

export default function AppLinks({ data }) {
	return (
		<section className={data.appShow ? 'appLinksContainer' : 'appLinksHidden'}>
			<div>
				<h3 className="getAppTitle">{data?.title}</h3>
			</div>
			<div className="rowImages">
				{data.googlePlayLink &&
					<a target="_blank" rel="noopener noreferrer" href={data?.googlePlayLink} className="linkImage">
						<img src={PlayStoreImage} alt="" />
					</a>}

				{data.appStoreLink &&
					<a target="_blank" rel="noopener noreferrer" href={data?.appStoreLink} className="linkImage">
						<img src={AppStoreImage} alt="" />
					</a>}
			</div>
		</section>
	)
}
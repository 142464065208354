import { ErrorStatusTypes } from '../constants/ErrorStatusTypes';
import routeNames from '../constants/routeNames';
import moment from 'moment';



const routeHelper = (type, content, history, data, registerData) => {
    const c = content?.pages?.errors_list?.content;
    let redeemDate;
    let redeemDateFormatted;
    let now = new Date();

    if (type === ErrorStatusTypes.ALREADY_REDEEMED_NO_LIMIT && registerData) {
        redeemDate = new Date(registerData.error.data.redeemDate);
        now.setDate(now.getDate() + 1);
        if (
            redeemDate.getFullYear() === now.getFullYear() &&
            redeemDate.getMonth() === now.getMonth() &&
            redeemDate.getDate() === now.getDate()
        ) {
            redeemDate = 'tomorrow';
        } else {
            redeemDate = `on ${moment(registerData.error.data.redeemDate).format('dddd, Do MMMM')}`;
        }
        redeemDateFormatted = `${redeemDate}`;



    }


    const messages = {
        [ErrorStatusTypes.RETAILER_LIMIT]: {
            title: c?.retailer_limit_error_title?.value,
            desc: c?.retailer_limit_error_description?.value,
        },
        [ErrorStatusTypes.DAILY_LIMIT]: {
            title: c?.daily_limit_error_title?.value,
            desc: c?.daily_limit_error_description?.value,
        },
        [ErrorStatusTypes.CAMPAIGN_LIMIT]: {
            title: c?.campaign_limit_error_title?.value,
            desc: c?.campaign_limit_error_description?.value,
        },
        [ErrorStatusTypes.RETAILER_OFF]: {
            title: c?.retailer_off_error_title?.value,
            desc: c?.retailer_off_error_description?.value,
        },
        [ErrorStatusTypes.ALREADY_SIGNUP]: {
            title: c?.already_signed_up_not_redeemed_title?.value,
            desc: c?.already_signed_up_not_redeemed_description?.value,
            button: c?.already_signed_up_not_redeemed_button_text?.value
        },
        [ErrorStatusTypes.ALREADY_REDEEMED_NO_LIMIT]: {
            title: c?.already_redeemed_no_limit_title?.value,
            desc: `${c?.already_redeemed_no_limit_description?.value} ${redeemDateFormatted}`,
        },
        [ErrorStatusTypes.NO_LOCATION]: {
            title: c?.location_error_title?.value,
            desc : c?.check_faq?.value
        }
    };

    switch (type) {
        case ErrorStatusTypes.DAILY_LIMIT:
            history.push(routeNames.NOT_AVAILABLE_DAILY_LIMIT,
                {
                    title: messages[ErrorStatusTypes.DAILY_LIMIT].title
                    , desc: messages[ErrorStatusTypes.DAILY_LIMIT].desc
                })
            break;
        case ErrorStatusTypes.RETAILER_LIMIT:
            history.push(routeNames.NOT_AVAILABLE_RETAILER_LIMIT,
                {
                    title: messages[ErrorStatusTypes.RETAILER_LIMIT].title
                    , desc: messages[ErrorStatusTypes.RETAILER_LIMIT].desc
                })
            break;
        case ErrorStatusTypes.CAMPAIGN_LIMIT:
            history.push(routeNames.NOT_AVAILABLE_CAMPAIGN_LIMIT,
                {
                    title: messages[ErrorStatusTypes.CAMPAIGN_LIMIT].title
                    , desc: messages[ErrorStatusTypes.CAMPAIGN_LIMIT].desc
                })
            break;
        case ErrorStatusTypes.RETAILER_OFF:
            history.push(routeNames.NOT_AVAILABLE_RETAILER_OFF,
                {
                    title: messages[ErrorStatusTypes.RETAILER_OFF].title
                    , desc: messages[ErrorStatusTypes.RETAILER_OFF].desc
                })
            break;
        case ErrorStatusTypes.CAMPAIGN_OFF:
            history.push(routeNames.PROMOTION_CLOSED)
            break;
        case ErrorStatusTypes.NO_LOCATION:
            history.push(routeNames.NO_LOCATION,
                {
                    title: messages[ErrorStatusTypes.NO_LOCATION].title
                    , desc: messages[ErrorStatusTypes.NO_LOCATION].desc
                })
            break;
        case ErrorStatusTypes.ALREADY_SIGNUP:
            history.push(routeNames.ALREADY_SIGNED_UP,
                {
                    title: messages[ErrorStatusTypes.ALREADY_SIGNUP].title
                    , desc: messages[ErrorStatusTypes.ALREADY_SIGNUP].desc,
                    button: messages[ErrorStatusTypes.ALREADY_SIGNUP].button,
                    data: data
                })
            break;
        case ErrorStatusTypes.ALREADY_REDEEMED_NO_LIMIT:
            history.push(routeNames.ALREADY_REDEEMED_NO_LIMIT,
                {
                    title: messages[ErrorStatusTypes.ALREADY_REDEEMED_NO_LIMIT].title
                    , desc: messages[ErrorStatusTypes.ALREADY_REDEEMED_NO_LIMIT].desc,
                    type: true
                })
        default:
            break;
    };
}

export default routeHelper;
import React, { useEffect, useContext } from 'react';
import { LocationContext } from '../../contexts/LocationContext';
import Api from '../../api';
import ResultIcon from '../../assets/images/mesh/map-marker-alt-solid.png';
import CurrentIcon from '../../assets/images/icon-map-current.png';
import L from 'leaflet';
import { MapContainer, Marker as LMarker, TileLayer } from 'react-leaflet';
import SetBoundsRectangles from './SetBoundsRectangles';

function LocationMap({
    locationConfirmFunction,
    clickable = true,
    modalVisible,
    setModalVisible,
    currentlySelected,
}) {
    const { userLocation, nearbyLocations, setNearbyLocations } = useContext(LocationContext);

    useEffect(() => {
        if (!userLocation) return;
        if (clickable) {
            setNearbyLocations([]);
            (async () => {
                let locationsResp = await Api.getNearbyLocations({
                    latitude: userLocation.lat,
                    longitude: userLocation.lng,
                });
                setNearbyLocations(locationsResp.data);
            })();
        }
        // eslint-disable-next-line
    }, [userLocation]);

    const displayLeafletMarkers = () => {
        return (
           
            nearbyLocations !== null &&
            nearbyLocations[0] !== null &&
            nearbyLocations.map(location => {
                let icon = L.icon({
                    iconUrl: location.markerImage || ResultIcon,
                    iconSize: [32, 32],
                    iconAnchor: [32, 32],
                });
                return (
                    <LMarker
                        key={location._id}
                        id={location._id}
                        name={location.name}
                        position={[location.latitude, location.longitude]}
                        eventHandlers={{
                            click: e => {
                                handleListItemClick(location);
                            },
                        }}
                        icon={icon}
                        
                    />
                );
            })
        );
    };

    const handleListItemClick = location => {
        if (!clickable) return;
        currentlySelected.setCSL(location);
        setModalVisible(true);
    };

    return (
        <section className="locations">
            <div className="map-wrapper">
                {userLocation  && (
                    <>
                        <MapContainer
                            bounds={[userLocation]}
                            scrollWheelZoom={true}
                            zoom={13}
                            style={{ height: 400 }}
                            zoomControl={false}                            
                        >
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            {userLocation && (
                                <LMarker
                                    position={[userLocation.lat, userLocation.lng]}
                                    icon={L.icon({
                                        iconUrl: CurrentIcon,
                                        iconSize: [32, 32],
                                        iconAnchor: [32, 32],
                                    })}
                                />
                            )}
                            {displayLeafletMarkers()}
                            <SetBoundsRectangles
                                userLocation={userLocation}
                                nearbyLocations={nearbyLocations}
                            />
                        </MapContainer>
                    </>
                )}
            </div>

            <ul className="locations-list" style={{ position: 'relative', zIndex: 401 }}>
                {nearbyLocations &&
                    nearbyLocations.map(location => (
                        <li key={location._id} onClick={() => handleListItemClick(location)}>
                            <h3>{location.name}</h3>
                            <p>{location.formattedAddress}</p>
                        </li>
                    ))}
            </ul>
        </section>
    );
}

export default LocationMap;

import React, { useState, useEffect, useContext } from 'react';
import { useRouteMatch, useHistory } from 'react-router';
import { LocationContext } from '../../contexts/LocationContext';
import { LoadingContext } from '../../contexts/LoadingContext';
import Api from '../../api/Api';
import PageSlug from '../../components/PageSlug';
import LocationMap from '../../components/LocationInterface/LocationMap';
import './LocationErrorPage.css';
import routeNames from '../../constants/routeNames';
import Brand from '../../components/Brand';
import Footer from '../../components/Footer';
import { ContentContext } from '../../contexts/ContentContext';
import SocialShares from '../../components/SocialShares/SocialShares';
import WhatsappButton from '../../components/WhatsappButton';
import { Link } from 'react-router-dom';
import routeHelper from '../../helpers/routeHelper';

function LocationErrorPage({ location }) {
    const history = useHistory();
    const {
        byUser,
        userLocation,
        locateMe,
        selectedLocation,
        setSelectedLocation,
        nearbyLocations,
        setNearbyLocations,
    } = useContext(LocationContext);
    const [currentDistance, setCurrentDistance] = useState();
    const match = useRouteMatch(`${routeNames.LOCATION_ERROR}/:unique/:type/`);
    const [firstTime, setFirstTime] = useState(true);
    const [uniqueData, setUniqueData] = useState({});
    const {loading,setLoading} = useContext(LoadingContext)

    const { content } = useContext(ContentContext);
    const s = content?.pages?.wrong_location?.settings;
    const c = content?.pages?.wrong_location?.content;
    const generic = content?.pages?.wrong_location?.generic;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;

    const handleLocateMe = () => {
        locateMe();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    

    useEffect(() => {
        setLoading(true);
        window.scrollTo({ top: 0 });
        (async () => {
             locateMe();
            if (match) {
                const uniqueResponse = await Api.checkUnique(match.params.unique);
                let selectedVenue = await Api.getSelectedVenue(match.params.unique);
                if (uniqueResponse && uniqueResponse.data && uniqueResponse.data.swiped) {
                    history.push(routeNames.REDEEM, {
                        unique: match.params.unique,
                        type: match.params.type,
                        venueId: selectedVenue.data._id,
                        firstTime: false,
                        uniqueData: uniqueResponse.data,
                    });
                }
                if (uniqueResponse.data) {
                    setFirstTime(loading && false);
                    setUniqueData(uniqueResponse.data);
                }
                if (uniqueResponse && uniqueResponse.result) {
                    setSelectedLocation(selectedVenue.data);
                    setNearbyLocations([selectedVenue.data]);
                    // setLoading(loading && false);
                } else if (uniqueResponse.error.type) {
                    routeHelper(uniqueResponse.error.type, content, history, null, uniqueResponse);
                }
                else {
                    history.push(routeNames.ALREADY_REDEEMED, {
                        type: uniqueResponse?.error?.type,
                    });
                }
            }
            else {
                if (!selectedLocation) {
                    // setLoading(false);
                    history.push(routeNames.HOME);
                }
            }
        })()
        setLoading(false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setLoading(true);
        if (byUser && userLocation) {
            if (selectedLocation) {
                (async () => {
                    setLoading(false);
                    let distanceResponse = await Api.getDistanceBetween({
                        from: `${userLocation.lat},${userLocation.lng}`,
                        to: `${selectedLocation.latitude},${selectedLocation.longitude}`,
                        venueId: selectedLocation._id,
                    });
                    if (distanceResponse && distanceResponse.result) {
                        setCurrentDistance(distanceResponse.data);
                        if (distanceResponse.data && distanceResponse.data.isInArea) {
                            // setLoading(false);
                            history.push(routeNames.REDEEM, {
                                unique: location?.state?.unique ? location.state.unique : match.params.unique,
                                type: location?.state?.type ? location.state.type : match.params.type,
                                venueId: selectedLocation._id,
                                firstTime,
                                uniqueData,
                            });
                        } else {
                            // setLoading(false);
                          //  history.push(routeNames.LOCATION_ERROR)
                            
                        }

                    } else {
                         locateMe();
                    }

                })();
                // setLoading(false);
            }
        } else {
            locateMe();
        }
        setLoading(false);
        // eslint-disable-next-line
    }, [userLocation, selectedLocation]);

    const selectDifferentVenue = () => {
        setLoading(true)
        if (match) {
            history.push(`${routeNames.VENUES}/${match.params.unique}/${match.params.type}`);
        } else {
            history.goBack();
        }
    };



    return (!loading ?
        <div className={`page-wrapper ${s?.bg && g?.bg ? 'has-bg' : ''}`}>
             {s?.bg && g?.bg && (
                    <div
                        className={`${s?.bg && g?.bg ? 'with-bg' : ''}`}
                        style={{ backgroundImage: s?.bg && g?.bg && `url(${g?.bg})` }}
                    ></div>
                )}
            {s?.header && g?.header && <Brand image={g?.header} />}

            <main>
                {s?.logo && g?.logo && (
                    <section className="campaign-logo">
                        <Link to={routeNames.HOME} className="brand-logo">
                            <img
                                className="header-title-image"
                                alt=""
                                src={g?.logo}
                            />
                        </Link>
                    </section>
                )}

                <section className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`}>
                    <PageSlug title={c?.title?.value} />
                    <p>{c?.description?.value}</p>
                    {/* <p>{c?.details?.value}</p> */}
                </section>

                <section
                    className={`main-content location-interface ${s?.bg && g?.bg ? 'transparent-container' : ''
                        }`}
                >
                    <section className="padding-horizontal section-lite text-center section-distance ">
                        {userLocation && nearbyLocations && <LocationMap nearbyLocations={selectedLocation} clickable={false} />}
                        <p className="location-away-text" dangerouslySetInnerHTML={{ __html: generic?.you_are?.value }}></p>

                        <h2 className="distance-text">
                            {currentDistance &&
                                currentDistance.distance &&
                                currentDistance.distance}{' '}
                            {generic?.away?.value}
                        </h2>
                        <p dangerouslySetInnerHTML={{ __html: c?.current_location_description?.value }}></p>
                        <div className="button-gorup">
                            <button
                                className="btn btn-brand btn-capitalize btn-wide"
                                onClick={handleLocateMe}
                                dangerouslySetInnerHTML={{ __html: c?.keep_location_button?.value }}
                            >
                            </button>
                            <button
                                className="btn btn-wide btn-outline btn-capitalize btn-different"
                                onClick={selectDifferentVenue}
                                dangerouslySetInnerHTML={{ __html: c?.select_new_location_button?.value }}
                            >
                            </button>
                        </div>
                    </section>
                </section>
                <section className={'transparent-container ' + (!showSocials && !whatsapp ? 'hidden' : '')}>
                    {showSocials && (<SocialShares></SocialShares>)}
                    {whatsapp && <WhatsappButton />}
                </section>
                {s?.footer && (
                    <Footer className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`} />
                )}
            </main>
        </div> : ""
    );
}

export default LocationErrorPage;

import React, { useContext, useEffect, useState } from 'react';
import PageSlug from '../../components/PageSlug';
import { ContentContext } from '../../contexts/ContentContext';
import Brand from '../../components/Brand';
import Footer from '../../components/Footer';
import SocialShares from '../../components/SocialShares/SocialShares';
import WhatsappButton from '../../components/WhatsappButton';
import { Link } from 'react-router-dom';
import routeNames from '../../constants/routeNames';

import "./NotAvailablePage.css"



export default function NotAvailablePage({ location }) {
    const { content } = useContext(ContentContext);
    const c = content?.pages?.errors_list?.content;
    const s = content?.pages?.errors_list?.settings;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;
    const [title, settitle] = useState('');
    const [desc, setdesc] = useState('');

    useEffect(() => {
        settitle(location?.state?.title);
        setdesc(location?.state?.desc);
    },[location])


    return (
        <>
            <div className={`page-wrapper ${s?.bg && g?.bg ? 'has-bg' : ''}`}>
                 {s?.bg && g?.bg && (
                    <div
                        className={`${s?.bg && g?.bg ? 'with-bg' : ''}`}
                        style={{ backgroundImage: s?.bg && g?.bg && `url(${g?.bg})` }}
                    ></div>
                )}
                {s?.header && g?.header && <Brand image={g?.header} />}
                <main>
                    {s?.logo && g?.logo && (
                        <section className="campaign-logo">
                            <Link to={routeNames.HOME} className="brand-logo">
                                <img
                                    className="header-title-image"
                                    alt=""
                                    src={g?.logo}
                                />
                            </Link>
                        </section>
                    )}
                    <section
                        className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''}`}
                    >
                        <section className="section-lite text-center card section-padding-small">
                            <PageSlug title={title}>
                                <p dangerouslySetInnerHTML={{ __html: desc }}></p>
                            </PageSlug>
                        </section>
                    </section>
                    <section
                        className={
                            'transparent-container ' + (!showSocials && !whatsapp ? 'hidden' : '')
                        }
                    >
                        {showSocials && <SocialShares></SocialShares>}
                        {whatsapp && <WhatsappButton />}
                    </section>
                    {s?.footer && (
                        <Footer className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`} />
                    )}
                </main>
            </div>
        </>
    );
}

import React from "react";
import { useForm } from "react-hook-form";
import Api from "../../api";
import './ResendEmailForm.css';
function ResendEmailForm({ setResendSuccess, content }) {
	const { register, handleSubmit, errors } = useForm();

	const onSubmit = async (formData) => {
		const resendResponse = await Api.resendEmail(formData.email);

		setResendSuccess(resendResponse);
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="form-row date-fields p-relative">

				<input
					tabIndex={3}
					type="email"
					name="email"
					placeholder={content?.email_label?.value}
					ref={register({
						required: `${content?.email_label_error_required?.value}`,
						pattern: {
							value: /^[a-zA-Z0-9.!#$%&’*=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
							message: `${content?.email_label_error_invalid?.value}`,
						},
					})}
				/>
				{errors.email && <p className="form-error">{errors.email.message}</p>}
				<button type="submit" className="btn btn-brand btn-wide btn-capitalize">
					<span dangerouslySetInnerHTML={{__html : content?.form_button?.value}}></span>
					<div className="inline-loading">
						<div className="lds-ring">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
				</button>

			</div>
		</form>

	)
}

export default ResendEmailForm;
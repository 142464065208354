import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../api';
import Brand from '../../components/Brand';
import Footer from '../../components/Footer';
import PageSlug from '../../components/PageSlug';
import SocialShares from '../../components/SocialShares/SocialShares';
import WhatsappButton from '../../components/WhatsappButton';
import routeNames from '../../constants/routeNames';
import { ContentContext } from '../../contexts/ContentContext';
import './AlreadySignedUpPage.css';

export default function AlreadySignedUpPage({ location }) {
    const { content } = useContext(ContentContext);
    const s = content?.pages?.errors_list?.settings;
    const c = content?.pages?.errors_list?.content;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;

    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [buttonText, setbuttonText] = useState('')


    const resendEmail = async () => {
        if (location?.state?.data?.email) {
            let resp = await Api.resendEmail(location?.state?.data?.email);
            if(resp.error){
                setDesc(resp.error);
            }else{
                resp && setDesc('Email sent!');
            }
        }
    };



    useEffect(() => {
        window.scrollTo({ top: 0 });
        setTitle(location?.state?.title);
        setDesc(location?.state?.desc);
        setbuttonText(location?.state?.button);


        // eslint-disable-next-line
    }, [location]);
    return (
        <div className={`page-wrapper ${s?.bg && g?.bg ? 'has-bg' : ''}`}>
             {s?.bg && g?.bg && (
                    <div
                        className={`${s?.bg && g?.bg ? 'with-bg' : ''}`}
                        style={{ backgroundImage: s?.bg && g?.bg && `url(${g?.bg})` }}
                    ></div>
                )}
            {s?.header && g?.header && <Brand image={g?.header} type={location?.state?.type} />}
            <main>
                {s?.logo && g?.logo && (
                    <section className="campaign-logo">
                        {location?.state?.type === 103 ?
                            (<div className="brand-logo">
                                <img
                                    className="header-title-image"
                                    alt=""
                                    src={g?.logo}
                                />
                            </div>) :
                            (<Link to={routeNames.HOME} className="brand-logo">
                                <img
                                    className="header-title-image"
                                    alt=""
                                    src={g?.logo}
                                />
                            </Link>)
                        }
                    </section>
                )}

                <section
                    className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''}`}
                >
                    <PageSlug title={title} />
                    <div className="redeem-instructions" style={{ margin: '0 0 0 0' }}>
                        <p dangerouslySetInnerHTML={{ __html: desc }}></p>
                        <button

                            style={{ fontSize: 18 }}

                            onClick={resendEmail}

                            className="btn btn-brand mb-35"

                            dangerouslySetInnerHTML={{ __html: buttonText }}
                        >

                        </button>
                    </div>
                    {s?.body && <img className="mb-n mt-n body-img" alt="" src={g?.body} />}

                </section>

                <section className={'transparent-container ' + (!showSocials && !whatsapp ? 'hidden' : '')}>
                    {showSocials && (<SocialShares></SocialShares>)}
                    {whatsapp && <WhatsappButton />}
                </section>

                {s?.footer && (
                    <Footer className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`} />
                )}
            </main>
        </div>
    );
}

const device = {
	Android: function () {
		return navigator.userAgent.match(/Android/i);
	},
	BlackBerry: function () {
		return navigator.userAgent.match(/BlackBerry/i);
	},
	iOS: function () {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	},
	any: function () {
		return (device.Android() || device.iOS() || device.BlackBerry());
	}
};

const isMobile = (device.any() !== null);

export default isMobile;
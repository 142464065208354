import React, { useEffect, useRef } from 'react';
import CheckBox from '../CheckBox/';
import { useForm } from 'react-hook-form';
import './RegisterForm.css';
import Api from '../../api';

function RegisterForm({
    data,
    setData,
    setRegisterSuccess,
    content,
    formContent,
    recaptchaSiteKey,
}) {
    const { register, handleSubmit, errors } = useForm();
    const submitBtn = useRef(null);
    const getV3Token = async () => {
        return new Promise((res, rej) => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(recaptchaSiteKey, { action: 'submit' }).then(token => {
                    return res(token);
                });
            });
        });
    };

    const validateV3Token = async () => {
        const token = await getV3Token();
        const res = await Api.verifyRecaptchaToken(token);
        if (res && res?.result && res?.data) {
            return true;
        } else {
            return false;
        }
    };

    const onSubmit = async formData => {
        submitBtn.current.classList.add('loading');
        const v3Result = await validateV3Token();
        if (v3Result) {
            const combinedData = { ...formData, ...data };
            const origin = sessionStorage.getItem('ORIGIN');
            combinedData.socialType = origin;
            setData(combinedData);
            const checkUser = await Api.checkUser(combinedData.email);
            document.getElementsByTagName('body')[0].classList.remove('show-badge');
            submitBtn.current.classList.remove('loading');
            setRegisterSuccess(checkUser);
        } else {
            window.location.href = '/';
        }
    };

    return (
        <section className="register-form">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-wrapper">
                    <input
                        tabIndex={1}
                        type="text"
                        name="firstName"
                        placeholder={content?.name_label?.value}
                        ref={register({
                            required: `${content?.name_label_error?.value}`,
                            maxLength: 20,
                        })}
                    />
                    {errors.firstName && (
                        <p className="form-validation-error">{errors.firstName.message}</p>
                    )}
                </div>
                <div className="input-wrapper">
                    <input
                        tabIndex={2}
                        type="text"
                        name="lastName"
                        placeholder={content?.last_name_label?.value}
                        ref={register({
                            required: `${content?.last_name_label_error?.value}`,
                            maxLength: 20,
                        })}
                    />
                </div>
                {errors.lastName && (
                    <p className="form-validation-error">{errors.lastName.message}</p>
                )}

                <div className="input-wrapper wide-desktop">
                    <input
                        tabIndex={3}
                        type="email"
                        name="email"
                        placeholder={content?.email_label?.value}
                        ref={register({
                            required: `${content?.email_label_error_required?.value}`,
                            pattern: {
                                value: /^[a-zA-Z0-9.!#$%&’*=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: `${content?.email_label_error_invalid?.value}`,
                            },
                        })}
                    />
                </div>
                {errors.email && <p className="form-validation-error">{errors.email.message}</p>}

                <div className="checkboxes-wrapper">
                    <div className="input-wrapper wide-desktop">
                        <CheckBox
                            tabIndex={5}
                            name="terms"
                            ref={register({
                                required: `${content?.terms_label_error?.value}`,
                            })}
                        >
                            {' '}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: formContent?.terms_label?.value,
                                }}
                            ></span>
                        </CheckBox>
                        {formContent?.terms_description?.value != '' && (
                            <small
                                className="description-box"
                                dangerouslySetInnerHTML={{
                                    __html: formContent?.terms_description?.value,
                                }}
                            ></small>
                        )}
                    </div>
                    {errors.terms && <p className="form-error">{errors.terms.message}</p>}

                    <div className="input-wrapper wide-desktop">
                        {formContent?.offers_label && formContent?.offers_label.value !== '' && (
                            <CheckBox tabIndex={6} name="subscription" ref={register}>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: formContent?.offers_label?.value,
                                    }}
                                ></span>
                            </CheckBox>
                        )}
                        {formContent?.offers_description?.value !== '' && (
                            <small
                                className="description-box"
                                dangerouslySetInnerHTML={{
                                    __html: formContent?.offers_description?.value,
                                }}
                            ></small>
                        )}
                    </div>
                </div>
                <div className="input-wrapper input-block">
                    <button ref={submitBtn} type="submit" className="btn btn-brand btn-wide">
                        <span
                            dangerouslySetInnerHTML={{ __html: formContent?.form_button?.value }}
                        ></span>
                        <div className="inline-loading">
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </button>
                </div>
            </form>
        </section>
    );
}

export default RegisterForm;

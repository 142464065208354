const API_URL = process.env.REACT_APP_API_URL;
const baseApiUrl = `${API_URL}/api/user`;

export default {
	signup: `${baseApiUrl}/signup`,
	locations: `${baseApiUrl}/locations`,
	unique: `${baseApiUrl}/unique-code/`,
	distance: `${baseApiUrl}/distance/`,
	swipe: `${baseApiUrl}/swipe/`,
	redeem: `${baseApiUrl}/redeem/`,
	venueSelect: `${baseApiUrl}/venue-select/`,
	selectedVenue: `${baseApiUrl}/selected-venue/`,
	checkUser: `${baseApiUrl}/check-user/`,
	origin: `${baseApiUrl}/landing/`,
	checkCampaign: `${baseApiUrl}/check-campaign`,
	resendEmail: `${baseApiUrl}/resend-email/`,
	getCampaignContent: `${baseApiUrl}/campaign`,
	verifyRecaptchaToken: `${baseApiUrl}/verify-token`
};
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import './AgeConfirmationForm.css';
import helper from '../../helpers/staticData';

moment.suppressDeprecationWarnings = true;

function AgeConfirmationForm({ setRedirect, setData, requiredAge, generic }) {
    const [showAgeMessage, setShowAgeMessage] = useState(false);
    const [showInvalidDateMessage, setShowInvalidDateMessage] = useState(false);
    const [form, setForm] = useState({});
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = async formData => {
        const today = new Date();
        setShowInvalidDateMessage(false);

        const selectedDate = new Date(`${formData.year}/${formData.month}/${formData.day}`);
        const momentDate = moment(`${formData.year}-${formData.month}-${formData.day}`);
        !momentDate.isValid() && setShowInvalidDateMessage(true);

        let mili_dif = Math.abs(today.getTime() - selectedDate.getTime());
        let age = mili_dif / (1000 * 3600 * 24 * 365.25);
        age = Number(age.toFixed(3));

        if (age <= requiredAge) {
            setShowAgeMessage(true);
            return;
        }

        setData({
            date: `${formData.day}/${formData.month}/${formData.year}`,
            country: formData.country,
        });
        setRedirect(true);
    };

    const validateDate = e => {
        let tempForm = { ...form };
        tempForm[e.target.name] = e.target.value;
        setForm(tempForm);
    };

    useEffect(() => {
        const momentDate = moment(`${form.year}-${form.month}-${form.day}`);
        !momentDate.isValid() && Object.keys(form).length === 3
            ? setShowInvalidDateMessage(true)
            : setShowInvalidDateMessage(false);
    }, [form]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row date-fields p-relative">
                    <div className="select-wrapper">
                        <select
                            value={form.day}
                            onChange={validateDate}
                            name="day"
                            ref={register({ validate: value => value !== '--' })}
                        >
                            <option value="--">DD</option>
                            {helper.dayOptions()}
                        </select>
                    </div>

                    <div className="select-wrapper">
                        <select
                            value={form.month}
                            onChange={validateDate}
                            name="month"
                            ref={register({ validate: value => value !== '--' })}
                        >
                            <option value="--">MM</option>
                            {helper.monthOptions()}
                        </select>
                    </div>

                    <div className="select-wrapper">
                        <select
                            value={form.year}
                            onChange={validateDate}
                            name="year"
                            ref={register({ validate: value => value !== '--' })}
                        >
                            <option value="--">YYYY</option>
                            {helper.yearOptions()}
                        </select>
                    </div>
                </div>
                {(errors.day || errors.month || errors.year) && (
                    <p className="form-validation-error">Please select a valid date</p>
                )}
                {showInvalidDateMessage && (
                    <p className="form-validation-error">Please choose a valid date</p>
                )}
                {!showInvalidDateMessage && showAgeMessage && (
                    <p className="form-validation-error" dangerouslySetInnerHTML={{__html : generic.form_warning.value}}>
                    </p>
                )}
                <div className="select-wrapper p-relative">
                    <select name="country" ref={register({ validate: value => value !== '--' })}>
                        <option value="--">Select a country</option>
                        {helper.getCountries().map((country, i) => (
                            <option key={i} value={country}>
                                {country}
                            </option>
                        ))}
                    </select>
                </div>
                {errors.country && (
                    <p className="form-validation-error-bottom">Please select a country</p>
                )}
                <button
                    type="submit"
                    className="btn btn-brand btn-block"
                    disabled={showInvalidDateMessage}
                    dangerouslySetInnerHTML={{__html : generic.form_button.value}}
                >
                </button>
            </form>

            <div
                style={{ display: 'none' }}
                className="fb-login-button"
                data-width=""
                data-size="small"
                data-button-type="continue_with"
                data-auto-logout-link="false"
                data-use-continue-as="false"
            ></div>
        </>
    );
}

export default AgeConfirmationForm;

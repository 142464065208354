import React, { useContext } from 'react';
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    PinterestShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from 'react-share';
import { ContentContext } from '../../contexts/ContentContext';

import './SocialShares.css';

export default function SocialShares({ children, origin, uniqueId }) {
    const { content } = useContext(ContentContext);
    const socials = content?.global_settings?.socials;

    const list = ['twitter', 'facebook', 'email',];

    const shareBtn = btn => {
        switch (btn) {
            case 'email':
                if (!socials?.email) return null;
                return (
                    <EmailShareButton
                        children={<i className="fas fa-envelope"></i>}
                        subject={socials?.email_subject}
                        url={socials?.email}
                    />
                );
            case 'facebook':
                if (!socials?.facebook) return null;
                return (
                    <FacebookShareButton
                        children={<i className="fab fa-facebook"></i>}
                        url={content?.campaignDomain}
                        title={socials?.facebook}                    />
                );
            case 'twitter':
                if (!socials?.twitter) return null;
 
                return (
                    <TwitterShareButton
                        children={<i className="fab fa-twitter"></i>}
                        url={content?.campaignDomain}
                        title={socials?.twitter}
                        quote={socials?.twitter}
                    />
                );

            default:
                return null;
        }
    };
    return (
        <div className="social-shares-wrapper">
            {list.length !== 0 && (
                <ul className="inline-list list-social-media social-shares">
                    {list.map(btn => (socials[btn] ? <li key={btn} style={{marginBottom: 0}}>{shareBtn(btn)}</li> : null))}
                </ul>
            )}
            <div>{children}</div>
        </div>
    );
}

import React from 'react';
import LocationForm from './LocationForm';
import LocationMap from './LocationMap';
import './LocationInterface.css';

function LocationInterface({ mapClickable, locationConfirmFunction, modalVisible, setModalVisible, currentlySelected }) {
    return (
        <section className="locaiton-interface">
			<div>
				<LocationForm />
			</div>
            <LocationMap
				currentlySelected={currentlySelected}
                clickable={mapClickable}
                locationConfirmFunction={locationConfirmFunction}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
            />
        </section>
    );
}

export default LocationInterface;

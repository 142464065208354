import React, { useState, useEffect, useContext } from 'react';
import { useRouteMatch, useHistory } from 'react-router';
import { LocationContext } from '../../contexts/LocationContext';
import { LoadingContext } from '../../contexts/LoadingContext';
import Api from '../../api/Api';
import PageSlug from '../../components/PageSlug';
import LocationMap from '../../components/LocationInterface/LocationMap';
import './LocationCheckPage.css';
import routeNames from '../../constants/routeNames';
import Brand from '../../components/Brand';
import Footer from '../../components/Footer';
import { ContentContext } from '../../contexts/ContentContext';
import SocialShares from '../../components/SocialShares/SocialShares';
import WhatsappButton from '../../components/WhatsappButton';
import { Link } from 'react-router-dom';
import routeHelper from '../../helpers/routeHelper';

function LocationCheckPage({ location }) {
    const history = useHistory();
    const {
        byUser,
        userLocation,
        locateMe,
        selectedLocation,
        setSelectedLocation,
        nearbyLocations,
        setNearbyLocations,
    } = useContext(LocationContext);
    const [currentDistance, setCurrentDistance] = useState();
    const match = useRouteMatch(`${routeNames.LOCATION_CHECK}/:unique/:type/`);
    const [firstTime, setFirstTime] = useState(true);
    const [uniqueData, setUniqueData] = useState({});
    const {loading,setLoading} = useContext(LoadingContext)
    const { content } = useContext(ContentContext);
    const s = content?.pages?.wrong_location?.settings;
    const c = content?.pages?.wrong_location?.content;
    const generic = content?.pages?.wrong_location?.generic;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;

    const handleLocateMe = () => {
        locateMe();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.scrollTo({ top: 0 });
        (async () => {
             locateMe();
             setLoading(true);
            if (match) {
                const uniqueResponse = await Api.checkUnique(match.params.unique);
                let selectedVenue = await Api.getSelectedVenue(match.params.unique);
                if (uniqueResponse && uniqueResponse.data && uniqueResponse.data.swiped) {
                    history.push(routeNames.REDEEM, {
                        unique: match.params.unique,
                        type: match.params.type,
                        venueId: selectedVenue.data._id,
                        firstTime: false,
                        uniqueData: uniqueResponse.data,
                    });
                }
                if (uniqueResponse.data) {
                    setFirstTime(false);
                    setUniqueData(uniqueResponse.data);
                }
                if (uniqueResponse && uniqueResponse.result) {
                    setSelectedLocation(selectedVenue.data);
                    setNearbyLocations([selectedVenue.data]);
                } else if (uniqueResponse.error.type) {
                    routeHelper(uniqueResponse.error.type, content, history, null, uniqueResponse);
                }
                else {
                    history.push(routeNames.ALREADY_REDEEMED, {
                        type: uniqueResponse?.error?.type,
                    });
                }
            }
            else {
                if (!selectedLocation) {
                    history.push(routeNames.HOME);
                }
            }
            setLoading(false);
        })()

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setLoading(true);
        if (byUser && userLocation) {
            if (selectedLocation) {
                (async () => {
                    let distanceResponse = await Api.getDistanceBetween({
                        from: `${userLocation.lat},${userLocation.lng}`,
                        to: `${selectedLocation.latitude},${selectedLocation.longitude}`,
                        venueId: selectedLocation._id,
                    });
                    if (distanceResponse && distanceResponse.result) {
                        setCurrentDistance(distanceResponse.data);
                        if (distanceResponse.data && distanceResponse.data.isInArea) {
                            history.push(routeNames.REDEEM, {
                                unique: location?.state?.unique ? location.state.unique : match.params.unique,
                                type: location?.state?.type ? location.state.type : match.params.type,
                                venueId: selectedLocation._id,
                                firstTime,
                                uniqueData,
                            });
                        } else {
                            history.push(`${routeNames.LOCATION_ERROR}/${match.params.unique}/${match.params.type}`);
                            
                        }

                    } else {
                         locateMe();
                    }

                })();
            }
        } else {
            locateMe();
        }
        setLoading(false);
        // eslint-disable-next-line
    }, [userLocation, selectedLocation]);

    const selectDifferentVenue = () => {
        if (match) {
            history.push(`${routeNames.VENUES}/${match.params.unique}/${match.params.type}`);
        } else {
            history.goBack();
        }
    };



    return (<></> );
}

export default LocationCheckPage;

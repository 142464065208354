import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Brand from '../../components/Brand';
import Footer from '../../components/Footer';
import PageSlug from '../../components/PageSlug';
import SocialShares from '../../components/SocialShares/SocialShares';
import WhatsappButton from '../../components/WhatsappButton';
import routeNames from '../../constants/routeNames';
import { ContentContext } from '../../contexts/ContentContext';

export default function TermsAndConditionsPage() {
    const { content } = useContext(ContentContext);
    const s = content?.pages?.terms_condition?.settings;
    const c = content?.pages?.terms_condition?.content;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    return (
        <div
            className={`page-wrapper ${s?.bg && g?.bg ? 'has-bg' : ''}`}
        >
             {s?.bg && g?.bg && (
                    <div
                        className={`${s?.bg && g?.bg ? 'with-bg' : ''}`}
                        style={{ backgroundImage: s?.bg && g?.bg && `url(${g?.bg})` }}
                    ></div>
                )}
            {s?.header && g?.header && <Brand image={g?.header} />}

            <main>
                {s?.logo && g?.logo && (
                    <section className="campaign-logo">
                        <a href={routeNames.HOME} className="brand-logo">
                            <img
                                className="header-title-image"
                                alt=""
                                src={g?.logo}
                            />
                        </a>
                    </section>
                )}

                <section
                    className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''}`}
                >
                    <PageSlug title={c?.title?.value} brand={true} />

                    <div className="the-content" dangerouslySetInnerHTML={{__html: c?.html_content?.value}}></div>
                </section>
                <section
                    className={
                        'transparent-container ' + (!showSocials && !whatsapp ? 'hidden' : '')
                    }
                >
                    {showSocials && <SocialShares></SocialShares>}
                    {whatsapp && <WhatsappButton />}
                </section>
                {s?.footer && (
                    <Footer className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`} />
                )}
            </main>
        </div>
    );
}

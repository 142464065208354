import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { LoadingContext } from '../../contexts/LoadingContext';
import AgeConfirmationForm from '../../components/AgeConfirmationForm';
import './AgeConfirmationPage.css';
import routeNames from '../../constants/routeNames';
import { ContentContext } from '../../contexts/ContentContext';
import Brand from '../../components/Brand';
import Footer from '../../components/Footer';
import PageSlug from '../../components/PageSlug';
import SocialShares from '../../components/SocialShares/SocialShares';
import WhatsappButton from '../../components/WhatsappButton';
import { Link } from 'react-router-dom';

function AgeConfirmationPage() {
    const history = useHistory();
    const { loading, setLoading } = useContext(LoadingContext);
    const [redirect, setRedirect] = useState(false);
    const [data, setData] = useState(null);
    const { content } = useContext(ContentContext);
    const s = content?.pages?.age_gate?.settings;
    const c = content?.pages?.age_gate?.content;
    const generic = content?.pages?.age_gate?.generic;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;
    const requiredAge = content?.global_settings?.requiredAge;

    useEffect(() => {
        window.scrollTo({ top: 0 });
        document.getElementsByTagName('body')[0].classList.remove('show-badge');
        setLoading(false);
    }, []);

    useEffect(() => {
        if (redirect) {
            setLoading(true);
            history.push(routeNames.SIGN_UP, data);
        }
        // eslint-disable-next-line
    }, [redirect]);

    return (
        <div className={`page-wrapper ${s?.bg && g?.bg ? 'has-bg' : ''}`}>
            {s?.bg && g?.bg && (
                <div
                    className={`${s?.bg && g?.bg ? 'with-bg' : ''}`}
                    style={{ backgroundImage: s?.bg && g?.bg && `url(${g?.bg})` }}
                ></div>
            )}
            {s?.header && g?.header && <Brand image={g?.header} />}
            <main>
                {s?.logo && g?.logo && (
                    <section className="campaign-logo">
                        <Link to={routeNames.HOME} className="brand-logo">
                            <img className="header-title-image" alt="" src={g?.logo} />
                        </Link>
                    </section>
                )}

                <section
                    className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''}`}
                >
                    <PageSlug subtitle={c?.form_title?.value} />
                    <div className="age-confirmation">
                        <AgeConfirmationForm
                            generic={generic}
                            setRedirect={setRedirect}
                            setData={setData}
                            requiredAge={requiredAge}
                        />
                    </div>
                </section>

                <section
                    className={
                        'transparent-container ' + (!showSocials && !whatsapp ? 'hidden' : '')
                    }
                >
                    {showSocials && <SocialShares></SocialShares>}
                    {whatsapp && <WhatsappButton />}
                </section>

                {s?.footer && (
                    <Footer className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`} />
                )}
                
            </main>
        </div>
    );
}

export default AgeConfirmationPage;

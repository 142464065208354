import React, { useEffect, useContext, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import moment from 'moment';
import PageSlug from '../../components/PageSlug';
import { LocationContext } from '../../contexts/LocationContext';
import MethodText from '../../components/RedemptionMethods/MethodText';
import MethodImage from '../../components/RedemptionMethods/MethodImage';
import MethodQRCode from '../../components/RedemptionMethods/MethodQRCode';
import MethodBarcode from '../../components/RedemptionMethods/MethodBarcode';
import Countdown from 'react-countdown';
import routeNames from '../../constants/routeNames';
import AppLinks from '../../components/AppLinks';
import Api from '../../api';
import Brand from '../../components/Brand';
import Footer from '../../components/Footer';
import { ContentContext } from '../../contexts/ContentContext';
import SocialShares from '../../components/SocialShares/SocialShares';
import WhatsappButton from '../../components/WhatsappButton';
import routeHelper from '../../helpers/routeHelper';

export default function RedeemPage({ location }) {
    const { selectedLocation } = useContext(LocationContext);
    const [methods, setMethods] = useState([]);
    const history = useHistory();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedLoc, setselectedLoc] = useState(selectedLocation);
    const [showAlphanumeric, setShowAlphanumeric] = useState(false);
    const [mobileApp, setMobileApp] = useState(null);
    const [activeImage, setActiveImage] = useState(null)
    const { content } = useContext(ContentContext);

    const s = content?.pages?.voucher?.settings;
    const c = content?.pages?.voucher?.content;
    const generic = content?.pages?.voucher?.generic;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;

    useEffect(() => {
        const strStartDate = location?.state?.data?.swipedDate;
        const startDate = new Date(strStartDate);
        const mEndDate = moment(strStartDate).add(1, 'hour');
        const endDate = new Date(mEndDate);
        setStartDate(startDate);
        setEndDate(endDate);
        setShowAlphanumeric(location?.state?.data?.showAlphanumeric);
        setMobileApp(location?.state?.data?.mobileApp);
        if (!selectedLocation) {
            (async function setSelectedData() {

                let selectedVenue = await Api.getSelectedVenue(location?.state?.unique);
                if (selectedVenue) {
                    setMobileApp(selectedVenue.extra.retailer.mobileApp);
                    if(selectedVenue.extra && selectedVenue?.extra?.activeImage && selectedVenue?.extra?.activeImage?.url){
                        setActiveImage(selectedVenue.extra.activeImage.url);
                    }
                    setselectedLoc(selectedVenue.data);
                    setMethods([
                        <MethodText code={location?.state?.data?.code} />,
                        <MethodImage image={selectedVenue?.extra?.activeImage?.url ? selectedVenue?.extra?.activeImage?.url :  null} />,
                        <MethodQRCode url={location?.state?.data?.code} />,
                        <MethodBarcode url={location?.state?.data?.code} />,
                    ]);
                }
            })();
        }
        // eslint-disable-next-line
    }, [activeImage]);



    useEffect(() => {
        window.scrollTo({ top: 0 });
        if (location?.state) {
            setMethods([
                <MethodText code={location?.state?.data?.code} />,
                <MethodImage image={activeImage ? activeImage :  location?.state?.data?.image} />,
                <MethodQRCode url={location?.state?.data?.code} />,
                <MethodBarcode url={location?.state?.data?.code} />,
            ]);
        }
        if (!selectedLocation && location?.state && location?.state?.data?.redemptionMethod === 1) {
            // history.push(routeNames.ALREADY_REDEEMED, { type: location?.error?.type })
        } else {
        }
        // eslint-disable-next-line
    }, []);

    const countdownRenderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            return <Redirect to={routeNames.REDEEM_SUCCESS} />;
            // return null
        } else {
            return (
                <div className="remaining-time">
                    <div>
                        {minutes < 10 && 0}
                        {minutes}
                    </div>
                    <span>:</span>
                    <div>
                        {seconds < 10 && 0}
                        {seconds}
                    </div>
                </div>
            );
        }
    };

    const handleBtnClick = async e => {
        if (location) {
            const response = await Api.redeemUniqueCode({
                code: location.state.unique,
                urlType: location.state.type,
                venueId: location.state.venueId,
            });
            if (response.result) {
                history.push(routeNames.REDEEM_SUCCESS);
            } else if (response.error) {
                routeHelper(response.error.type, content, history, null, response);
            }
        }
    };

    return (
        <div
            className={`page-wrapper ${s?.bg && g?.bg ? 'has-bg' : ''}`}
        >
             {s?.bg && g?.bg && (
                    <div
                        className={`${s?.bg && g?.bg ? 'with-bg' : ''}`}
                        style={{ backgroundImage: s?.bg && g?.bg && `url(${g?.bg})` }}
                    ></div>
                )}
            {s?.header && g?.header && <Brand image={g?.header} />}
            <main>
                {s?.logo && g?.logo && (
                    <section className="campaign-logo">
                        <Link to={routeNames.HOME} className="brand-logo">
                            <img
                                className="header-title-image"
                                alt=""
                                src={g?.logo}
                            />
                        </Link>
                    </section>
                )}

                <section
                    className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''}`}
                >
                    <PageSlug title={c?.title?.value}>
                        <p dangerouslySetInnerHTML={{ __html: c?.description?.value }}></p>
                    </PageSlug>
                    <p dangerouslySetInnerHTML={{ __html: c?.availability?.value }}></p>
                    {selectedLoc && (
                        <section className="section-lite text-center card location-result mx-0">
                            <h3>{selectedLoc?.name}</h3>
                            <p >{selectedLoc?.formattedAddress}</p>

                        </section>
                    )}

                    {location && location?.state && location?.state?.data?.text && (
                        <p>{location?.state?.data?.text}</p>

                    )}
                    <PageSlug h3title={c?.voucher_title?.value} />

                    
                    <PageSlug className="smaller" subtitle={c?.subtitle?.value} />

                    {location && location.state && (
                        <div className="code-wrapper">
                            {methods[location?.state?.data?.redemptionMethod]}
                            {showAlphanumeric &&
                                location.state.data.redemptionMethod !== 0 &&
                                methods[0]}
                        </div>
                    )}

                    {startDate && <p>{`${generic?.validity?.value} ${moment(startDate).format('HH:mm')} on ${startDate.toLocaleDateString()}`}</p>}
                    {endDate && <Countdown renderer={countdownRenderer} date={endDate} />}
                    <PageSlug h3title={generic?.after_claimed?.value} />
                    <button className="btn btn-brand btn-wide btn-mb-none" onClick={handleBtnClick} dangerouslySetInnerHTML={{ __html: generic?.button_text?.value }}>
                    </button>
                </section>

                {mobileApp && mobileApp.appShow == true && <section  className={`main-content appLinksSectionParent ${s?.bg && g?.bg ? 'transparent-container' : ''}`}>
                     <AppLinks data={mobileApp}/>
                </section>}

                <section className={'transparent-container ' + (!showSocials && !whatsapp ? 'hidden' : '')}>
                    {showSocials && (<SocialShares></SocialShares>)}
                    {whatsapp && <WhatsappButton />}
                </section>

                {s?.footer && (
                    <Footer className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`} />
                )}
            </main>
        </div>
    );
}

import React from 'react';
import './PageSlug.css';

function PageSlug({ title, subtitle, children, brand, className,h3title }) {
    return (
        <section className={'page-slug ' + (className || '')}>
            {title && title !== '' && <h1 className={brand ? 'brand-color' : null} dangerouslySetInnerHTML={{__html : title}}></h1>}
            {/* {title && title !== '' && <h1 className={brand ? 'brand-color' : null} >{title}</h1>} */}

            {subtitle && <h2 dangerouslySetInnerHTML={{__html : subtitle}}></h2>}
            {/* {subtitle && <h2>{subtitle}</h2>} */}

            {h3title && <h3 dangerouslySetInnerHTML={{__html : h3title}}></h3>}

            {children}
        </section>
    );
}

export default PageSlug;

import React, { useContext } from 'react';
import PageSlug from '../../components/PageSlug';
import { ContentContext } from '../../contexts/ContentContext';
import Brand from '../../components/Brand';
import Footer from '../../components/Footer';
import SocialShares from '../../components/SocialShares/SocialShares';
import WhatsappButton from '../../components/WhatsappButton';
import { Link } from 'react-router-dom';
import routeNames from '../../constants/routeNames';
import "./CampaignClosedPage.css"



export default function CampaignClosedPage(){
    const { content } = useContext(ContentContext);
    const c = content?.pages?.closed_page?.content;
    const s = content?.pages?.closed_page?.settings;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;


    return (
        <>


            <div className={`page-wrapper ${s?.bg && g?.bg ? 'has-bg' : ''}`}>
                     {s?.bg && g?.bg && (
                    <div
                        className={`${s?.bg && g?.bg ? 'with-bg' : ''}`}
                        style={{ backgroundImage: s?.bg && g?.bg && `url(${g?.bg})` }}
                    ></div>
                )}
                {s?.header && g?.header && <Brand type={103} image={g?.header} />}
                <main>
                    {s?.logo && g?.logo && (
                        <section className="campaign-logo">
                            <div className="brand-logo">
                                <img
                                    className="header-title-image"
                                    alt=""
                                    src={g?.logo}
                                />
                            </div>
                        </section>
                    )}
                    <section className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''}`}>
                    <section className="section-lite text-center card section-padding-small">
                        <PageSlug title={c?.title?.value}>
                            <p dangerouslySetInnerHTML={{ __html: c?.description?.value }}></p>
                        </PageSlug>
                    </section>
                    {s?.body && <img className="mb-n mt-n body-img" alt="" src={g?.body} />}

                    </section>
                    <section
                        className={
                            'transparent-container ' + (!showSocials && !whatsapp ? 'hidden' : '')
                        }
                    >
                        {showSocials && <SocialShares></SocialShares>}
                        {whatsapp && <WhatsappButton />}
                    </section>
                    {s?.footer && (
                        <Footer className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`} />
                    )}
                </main>
            </div>
        </>
    );
}

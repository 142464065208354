import './checkbox.css';
import React from 'react';

const CheckBox = React.forwardRef((props, ref) => (
    <label className="checkbox-container">
        <span>{props.children}</span>
        <input tabIndex={props.tabIndex} ref={ref} type="checkbox" name={props.name} />
        <span className="checkmark"></span>
    </label>
));

export default CheckBox;

import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

function SetBoundsRectangles({ userLocation, nearbyLocations }) {
    const map = useMap();

    useEffect(() => {
        let userBounds = [];
        const userCoordinates = [userLocation.lat, userLocation.lng];
        userLocation && userBounds.push(userCoordinates);

        nearbyLocations &&
            nearbyLocations.length !== 0 &&
            nearbyLocations.forEach(location => {
                userBounds.push([location?.latitude, location?.longitude]);
            });

        map.fitBounds(userBounds);
    }, [userLocation, nearbyLocations, map]);

    return null;
}

export default SetBoundsRectangles;

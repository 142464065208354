import React from 'react';
import { Link } from 'react-router-dom';
import './Brand.css';
import routeNames from '../../constants/routeNames';

function Brand({ image, type }) {
    if (!image) return null;
    if (!type) {
        type = null;
    }

    return (
        <section className="brand">
            {type !== 103 ? (
                <a href={routeNames.HOME} className="brand-logo">
                    <img src={image} alt="logo" />
                </a>
            ) : (
                <div className="brand-logo">
                    <img src={image} alt="logo" />
                </div>
            )}
        </section>
    );
}

export default Brand;

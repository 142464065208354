import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import LoadingContextProvider from './contexts/LoadingContext';
import App from './App';
import './assets/styles/reset.css';
import './assets/styles/global.css';
import ContentContextProvider from './contexts/ContentContext';
import { ErrorBoundary } from './components/ErrorBoundry/ErrorBoundry';

ReactDOM.render(
    <ContentContextProvider>
        <LoadingContextProvider>
            <Router>
                <ErrorBoundary>
                    <Suspense fallback={<div />}>
                        <App />
                    </Suspense>
                </ErrorBoundary>
            </Router>
        </LoadingContextProvider>
    </ContentContextProvider>,
    document.getElementById('root')
);

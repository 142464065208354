import React, { useContext } from 'react';
import { WhatsappShareButton } from 'react-share';
import { ContentContext } from '../contexts/ContentContext';

const WhatsappButton = () => {
    const { content } = useContext(ContentContext);
    const socials = content?.global_settings?.socials;
    return (
        <WhatsappShareButton url={content?.campaignDomain} title={socials?.whats_app}>
            <div className="whatsAppBtn">
                <div>
                    <i className="fab fa-whatsapp"></i>
                </div>
                <div>{content?.global_settings?.footer?.whats_app || 'Share on WhatsApp'}</div>
            </div>
        </WhatsappShareButton>
    );
};

export default WhatsappButton;

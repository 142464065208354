import Endpoints from './Endpoints';

const CAMPAIGNID = process.env.REACT_APP_CAMPAIGN_ID
const reqHeaders = new Headers();
reqHeaders.append('Content-Type', 'application/json');
reqHeaders.append('campaignid', CAMPAIGNID);

class Api {
    async register(formData) {
        const requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(formData),
        };

        try {
            const respJson = await fetch(Endpoints.signup, requestOptions);
            return await respJson.json();
        } catch (error) {
            return error.message;
        }
    }

    async checkUnique(id) {
        const requestOptions = {
            method: 'GET',
            headers: reqHeaders,
        };

        try {
            const respJson = await fetch(`${Endpoints.unique}${id}`, requestOptions);
            return await respJson.json();
        } catch (error) {
            return error.message;
        }
    }

    async getNearbyLocations(formData) {

        const requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(formData),
        };

        try {
            const respJson = await fetch(Endpoints.locations, requestOptions);
            return await respJson.json();
        } catch (error) {
            return error.message;
        }
    }

    async getDistanceBetween(formData) {
        const requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(formData),
        };

        try {
            const respJson = await fetch(Endpoints.distance, requestOptions);
            return await respJson.json();
        } catch (error) {
            return error.message;
        }
    }

    async swipeUniqueCode(formData) {
        const requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(formData),
        };

        try {
            const respJson = await fetch(Endpoints.swipe, requestOptions);
            let resp = await respJson.json();
            return resp;
        } catch (error) {
            return error.message;
        }
    }

    async redeemUniqueCode(formData) {
        const requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(formData),
        };

        try {
            const respJson = await fetch(Endpoints.redeem, requestOptions);
            let resp = await respJson.json();
            return resp;
        } catch (error) {
            return error.message;
        }
    }

    async setSelectedVenue(uniqueCode, venueId) {
        const formData = {
            code: uniqueCode,
            venueId: venueId,
        };
        const requestOptions = {
            method: 'POST',
            headers: reqHeaders,
            body: JSON.stringify(formData),
        };

        try {
            const respJson = await fetch(Endpoints.venueSelect, requestOptions);
            return await respJson.json();
        } catch (error) {
            return error.message;
        }
    }

    async getSelectedVenue(venueId) {
        const requestOptions = {
            method: 'GET',
            headers: reqHeaders,
        };

        try {
            const respJson = await fetch(`${Endpoints.selectedVenue}${venueId}`, requestOptions);
            return await respJson.json();
        } catch (error) {
            return error.message;
        }
    }

    async checkUser(email) {
        const requestOptions = {
            method: 'POST',
            headers: reqHeaders,
        };

        try {
            const respJson = await fetch(`${Endpoints.checkUser}${email}`, requestOptions);
            return await respJson.json();
        } catch (error) {
            return error.message;
        }
    }

    async sendOrigin(origin) {
        const requestOptions = {
            method: 'POST',
            headers: reqHeaders,
        };

        try {
            const respJson = await fetch(`${Endpoints.origin}${origin}`, requestOptions);
            const resp = await respJson.json();
            return resp;
        } catch (err) {
            return err.message;
        }
    }
    async checkCampaign() {
        const requestOptions = {
            method: 'GET',
            headers: reqHeaders,
        };

        try {
            const respJson = await fetch(`${Endpoints.checkCampaign}`, requestOptions);
            return await respJson.json();
        } catch (err) {
            return err.message;
        }
    }
    async resendEmail(email) {
        const requestOptions = {
            method: 'POST',
            headers: reqHeaders,
        };

        try {
            const respJson = await fetch(`${Endpoints.resendEmail}${email}`, requestOptions);
            const resp = await respJson.json();
            return resp;
        } catch (err) {
            return err.message;
        }
    }

    async verifyRecaptchaToken(token){
        const requestOptions = {
            method:"POST",
            headers : reqHeaders
        }
        try{
            const response = await fetch(`${Endpoints.verifyRecaptchaToken}/${token}`,requestOptions);
            const respJson = await response.json();
            return respJson;
        }catch(err){
            return err.message
        }
    }

    async getCampaignContent() {

        const requestOptions = {

            method: 'GET',

        };


        try {

            const respJson = await fetch(`${Endpoints.getCampaignContent}/${CAMPAIGNID}`, requestOptions);

            return await respJson.json();

        } catch (err) {

            return err.message;

        }

    }
}

export default new Api();

import React, { createContext, useState } from 'react';
export const BrandingContext = createContext({});

const BrandingContextProvider = ({ children }) => {
	const [branding, setBranding] = useState({
		socialLinks: [
			{
				name: 'instagram',
				url: 'fb.com',
				icon: 'fab fa-instagram',
			},
			{
				name: 'twitter',
				url: 'fb.com',
				icon: 'fab fa-twitter',
			},
			{
				name: 'facebook',
				url: 'fb.com',
				icon: 'fab fa-facebook',
			},
		],
	});
	return <BrandingContext.Provider value={{ branding, setBranding }}>{children}</BrandingContext.Provider>;
};

export default BrandingContextProvider;

import React, { createContext, useState, useEffect,useContext } from 'react';
import routeNames from '../constants/routeNames';
import { useHistory } from 'react-router-dom'
import Api from '../api';
import { ContentContext } from './ContentContext';
import routeHelper from '../helpers/routeHelper';
import { ErrorStatusTypes } from '../constants/ErrorStatusTypes';
export const LocationContext = createContext(null);

const LocationContextProvider = ({ children }) => {
	const {content} = useContext(ContentContext)
	const history = useHistory();
	const [nearbyLocations, setNearbyLocations] = useState(null);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [userLocation, setUserLocation] = useState(null);
	const [byUser, setByUser] = useState(false)
	const [askForLocation, setAskForLocation] = useState(false)
	const [locationDenied, setLocationDenied] = useState(false)

	const locateMe =  () => {
				window.navigator.geolocation.getCurrentPosition(
					(geo) => {
						setByUser(true)
						setLocationDenied(false)
						setUserLocation( { lat: geo.coords.latitude, lng: geo.coords.longitude });
	
					},
					(err) => {
						setLocationDenied(true)
						routeHelper(ErrorStatusTypes.NO_LOCATION,content,history);

	
					},
					{ enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
				);
		};

	

	useEffect(() => {
		askForLocation && locateMe();
		// eslint-disable-next-line
	}, [askForLocation])

	useEffect(() => {
		askForLocation && locateMe();
		// eslint-disable-next-line
	}, [])

	const value = {
		nearbyLocations,
		setNearbyLocations,
		selectedLocation,
		setSelectedLocation,
		userLocation,
		setUserLocation,
		locateMe,
		setAskForLocation,
		byUser,
		locationDenied
	};
	return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>;
};

export default LocationContextProvider;

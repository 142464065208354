import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import {LoadingContext} from '../../contexts/LoadingContext';
import { LocationContext } from '../../contexts/LocationContext';
import SwipeToRedeem from '../../components/SwipeToRedeem/SwipeToRedeem';
import PageSlug from '../../components/PageSlug';
import routeNames from '../../constants/routeNames';
import AppLinks from '../../components/AppLinks';
import './VoucherPage.css';
import Brand from '../../components/Brand';
import Footer from '../../components/Footer';
import { ContentContext } from '../../contexts/ContentContext';
import SocialShares from '../../components/SocialShares/SocialShares';
import WhatsappButton from '../../components/WhatsappButton';
import { Link } from 'react-router-dom';
import Api from '../../api';

export default function VoucherPage({ location }) {
    const { content } = useContext(ContentContext);
    const {loading,setLoading } = useContext(LoadingContext);

    const s = content?.pages?.swipe_to_redeem?.settings;
    const c = content?.pages?.swipe_to_redeem?.content;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;

    const { selectedLocation, setSelectedLocation, setNearbyLocations } = useContext(LocationContext);
    const history = useHistory();
    useEffect(() => {
        window.scrollTo({ top: 0 });
        setLoading(true);
        (async () => {
            if (!selectedLocation) {
                let selectedVenue = await Api.getSelectedVenue(location.state?.unique);
                setSelectedLocation(selectedVenue.data);
                setNearbyLocations([selectedVenue.data]);
            }
        })();

        if (!location.state) {
            history.push(routeNames.HOME);
        }
        if (!location.state?.firstTime) {
            history.push({
                pathname: routeNames.VOUCHER,
                state: {
                    data: location.state?.uniqueData,
                    unique: location.state?.unique,
                    type: location.state?.type,
                    venueId: location.state?.venueId,
                },
            });
        }
        setLoading(false)
        // eslint-disable-next-line
    }, []);
    return (
        <div
            className={`page-wrapper ${s?.bg && g?.bg ? 'has-bg' : ''}`}
        >
             {s?.bg && g?.bg && (
                    <div
                        className={`${s?.bg && g?.bg ? 'with-bg' : ''}`}
                        style={{ backgroundImage: s?.bg && g?.bg && `url(${g?.bg})` }}
                    ></div>
                )}
            {s?.header && g?.header && <Brand image={g?.header} />}
            <main>
                {s?.logo && g?.logo && (
                    <section className="campaign-logo">
                        <Link to={routeNames.HOME} className="brand-logo">
                            <img
                                className="header-title-image"
                                alt=""
                                src={g?.logo}
                            />
                        </Link>
                    </section>
                )}

                <section
                    className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''}`}
                >
                    <div className="margin-small">
                        <PageSlug className="mt-0">
                            <h1 className='brand-color mt-0' dangerouslySetInnerHTML={{ __html: c?.title?.value }}></h1>
                            <p dangerouslySetInnerHTML={{ __html: c?.description?.value }}></p>
                            {/* Can only set one to dangerouslySetInnerHTML */}

                        </PageSlug>
                        <p dangerouslySetInnerHTML={{ __html: c?.availability?.value }}></p>

                    </div>
                    {selectedLocation && (
                        <section className="section-lite text-center card location-result mx-0">
                            <h3>{selectedLocation.name}</h3>
                            <p>{selectedLocation.formattedAddress}</p>

                        </section>
                    )}
                    <p style={{marginBottom:'30px'}} dangerouslySetInnerHTML={{ __html: c?.swipe_instructions?.value }}></p>
                    {location.state && (
                        <SwipeToRedeem
                            unique={location.state.unique}
                            type={location.state.type}
                            venueId={location.state.venueId}
                            title={c?.swipe_title?.value}
                            
                        />
                    )}

                </section>
                <section className={'transparent-container ' + (!showSocials && !whatsapp ? 'hidden' : '')}>
                    {showSocials && (<SocialShares></SocialShares>)}
                    {whatsapp && <WhatsappButton />}
                </section>

                {s?.footer && (
                    <Footer className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`} />
                )}
            </main>
        </div>
    );
}

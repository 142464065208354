import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Brand from '../../components/Brand';
import Footer from '../../components/Footer';
import PageSlug from '../../components/PageSlug';
import SocialShares from '../../components/SocialShares/SocialShares';
import WhatsappButton from '../../components/WhatsappButton';
import routeNames from '../../constants/routeNames';
import { ContentContext } from '../../contexts/ContentContext';

export default function CookiePolicyPage() {
    const { content } = useContext(ContentContext);
    const s = content?.pages?.cookie_policy?.settings;
    const c = content?.pages?.cookie_policy?.content;
    const g = content?.global_settings?.images;
    const showSocials = s?.footer_social;
    const whatsapp = s?.whatsapp;

    useEffect(() => {
        window.scrollTo({ top: 0 });
        (function (d, s, id) {
            var js, tjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://app.termly.io/embed-policy.min.js";
            tjs.parentNode.insertBefore(js, tjs);
        }(document, 'script', 'termly-jssdk'));

    }, []);

    return (
        <div
            className={`page-wrapper ${s?.bg && g?.bg ? 'has-bg' : ''}`}
        >
            {s?.bg && g?.bg && (
                <div
                    className={`${s?.bg && g?.bg ? 'with-bg' : ''}`}
                    style={{ backgroundImage: s?.bg && g?.bg && `url(${g?.bg})` }}
                ></div>
            )}
            {s?.header && g?.header && <Brand image={g?.header} />}
            <main>
                {s?.logo && g?.logo && (
                    <section className="campaign-logo">
                        <Link to={routeNames.HOME} className="brand-logo">
                            <img
                                className="header-title-image"
                                alt=""
                                src={g?.logo}
                            />
                        </Link>
                    </section>
                )}
                <section
                    className={`main-content ${s?.bg && g?.bg ? 'transparent-container' : ''}`}
                >
                    <PageSlug title={c?.title?.value} brand={true} />
                    <div className="the-content">

                        {/* <h2 dangerouslySetInnerHTML={{__html:c?.title?.value}}></h2> */}

                        <div dangerouslySetInnerHTML={{ __html: c?.html_content?.value }}></div>

               

                        {/* <div name="termly-embed" data-id="d91a5478-3412-4d85-866e-4f9c6235f8f1" data-type="iframe"></div> */}


                        <div style={{
                            textAlign: 'center',
                        }}>
                            <button
                                className="termly-cookie-preference-button"
                                type="button"
                                style={{
                                    background: 'white',
                                    width: '165px',
                                    height: '30px',
                                    borderRadius: '3px',
                                    border: '1px solid #5f7d9c',
                                    fontFamily: 'Roboto, Arial',
                                    fontSize: '10px',
                                    color: '#5f7d9c',
                                    fontWeight: '600',
                                    boxSizing: 'border-box',
                                    cursor: 'pointer',
                                    padding: 0,
                                    outline: 'none',
                                }}
                                onClick={() => { window.displayPreferenceModal() }}

                            >
                                Manage Cookie Preferences
                            </button>
                        </div>


                    </div>
                </section>
                <section
                    className={
                        'transparent-container ' + (!showSocials && !whatsapp ? 'hidden' : '')
                    }
                >
                    {showSocials && <SocialShares></SocialShares>}
                    {whatsapp && <WhatsappButton />}
                </section>

                {s?.footer && (
                    <Footer className={`${s?.bg && g?.bg ? 'transparent-container' : ''}`} />
                )}
            </main>
        </div>
    );
}

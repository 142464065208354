export const ErrorStatusTypes = {
    RETAILER_LIMIT : 100,
    DAILY_LIMIT: 101,
    CAMPAIGN_LIMIT : 102,
    CAMPAIGN_OFF: 103,
    RETAILER_OFF: 104,
    ALREADY_SIGNUP: 105,
    ALREADY_REDEEMED_NO_LIMIT: 106,
    NO_LOCATION : 200,
}

